(function (angular, _) {
    var MyHippoCommonDirectives = angular.module('MyHippoCommons.Directives');

    MyHippoCommonDirectives.directive('mhSentCustomerAgreementModal', hippoSentCustomerAgreementModal);
    hippoSentCustomerAgreementModal.$inject = ['$timeout', '$parse'];
    function hippoSentCustomerAgreementModal (timeout, $parse) {
        return {
            restrict: 'E',
            scope: {
                dismissModal: '=',
                firstName: '@',
                email: '@'
            },
            template: `
                <mh-modal-wrapper accept-action="dismissModal"
                                  accept-copy="Ok"
                                  icon="fa-paper-plane-o"
                                  icon-color="blue">
                    <h1 class="title">Email was sent</h1>
                    <p class="body">
                        We just sent <b>{{firstName}}</b> the terms and conditions acceptance email to <b>{{email}}</b>
                    </p>
                </mh-modal-wrapper>
            `
        };
    }
})(window.angular, window._);
